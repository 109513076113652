import { ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIconTypeMap } from "@mui/material";
import { ReactNode } from "react";

interface ThemedListItemDrawerProps {
    key: string
    text?: string
    secondaryText?: string
    icon?: ReactNode
    end_icon?: ReactNode
    open: boolean
    content?: ReactNode
    minHeight?: number
    onClick?: () => void
    setContent?: (content: React.ReactNode) => void;
}

const ThemedListItemDrawer = (props: ThemedListItemDrawerProps) => {

    const open = props.open

    const handleClick = () => {
        if (!props.content && props.onClick) {
            props.onClick()
        } else if (props.setContent) {
            props.setContent(props.content);
        }
    };

    return (
        <ListItem key={props.key} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                onClick={() => handleClick()}
                sx={[
                    {
                        minHeight: props.minHeight,
                        px: 2.5,
                    },
                    open
                        ? {
                            justifyContent: 'initial',
                        }
                        : {
                            justifyContent: 'center',
                        },
                ]}>

                <ListItemIcon
                    sx={[
                        {
                            minWidth: 0,
                            justifyContent: 'center',
                        },
                        open
                            ? {
                                mr: 3,
                            }
                            : {
                                mr: 'auto',
                            },
                    ]}
                >
                    {props.icon}
                </ListItemIcon>
                <ListItemText
                    primary={props.text}
                    secondary={props.secondaryText}
                    sx={[
                        open
                            ? {
                                opacity: 1,
                            }
                            : {
                                opacity: 0,
                            },
                    ]}
                />
                {
                    open && props.end_icon ? <ListItemIcon>{props.end_icon}</ListItemIcon> : <></>
                }
            </ListItemButton>
        </ListItem>
    )
}


export default ThemedListItemDrawer