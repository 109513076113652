// CustomDialog.js
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(10),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  dialogTitle?: string
  dialogContent?: React.ReactNode | string
  dialogTopContent?: React.ReactNode | string
  actions?: React.ReactNode;
}

const CustomDialog: React.FC<CustomDialogProps> = ({ open, onClose, children, dialogTitle, dialogContent, dialogTopContent, actions }) => {

  console.log("Sono nella dialog");

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/*<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle>*/}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        {
          dialogTitle ?
            <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} id="customized-dialog-title">
              {dialogTitle}
            </DialogTitle> : <></>
        }

        <DialogContent dividers>
          <Box sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <Box>
              {dialogTopContent}
            </Box>
            {children}
            <Box>
              {dialogContent}
            </Box>
          </Box>
        </DialogContent>
        {
          actions ?
            <DialogActions>
              {actions}
            </DialogActions>
            : <></>
        }
      </BootstrapDialog>

    </React.Fragment>
  );
}

export default CustomDialog;