import { Box, Divider, Tab, Typography } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { fetch_new_attribute, fetch_refresh_token, UserInfo } from "../login/login_service";
import { getSavedAccessToken, getSavedRefreshToken, getSavedUserInfo } from "../../utils/localStorageUtils";
import ThemedTabs, { TabPanel, ThemedTab } from "../../theme/ThemedTabs";
import CreateIcon from '@mui/icons-material/Create';
import ReplayIcon from '@mui/icons-material/Replay';
import About from "./About";
import { capitalizeFirstLetter } from "../../utils/dataUtils";
import ThemedButton from "../../theme/ThemedButton";
import Grid from '@mui/material/Grid2';
import Services from "./Services";
import Account from "./Account";


const Profile = () => {

    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(getSavedUserInfo())
    const [editing, setEditing] = useState<boolean>(true)
    const [undo, setUndo] = useState<boolean>(false)
    const [data, setData] = useState<Record<string, any | undefined>>()
    const [accessToken, setAccessToken] = useState<string>(getSavedAccessToken())
    const [refreshToken, setRefreshToken] = useState<string>(getSavedRefreshToken())

    const handleEditing = (edit?: boolean) => {
        setEditing(edit ?? true)
    }

    const handleData = (data: any) => {
        setData(data)
    }

    const handleSave = async () => {
        var res = await fetch_new_attribute(data!, accessToken, refreshToken, "desktop")

        if (res.status == 200) {
            alert("salvato con successo")
            setEditing(!editing)
            console.log("User update", res.data);
        }
    }

    useEffect(() => {

        const loadUserInfo = async () => {
            const refreshRes = await fetch_refresh_token(refreshToken)
            setUserInfo(refreshRes.data.user_info)
        }

        loadUserInfo()

    }, [])

    return (

        <Box>
            <Box>
                <Grid container spacing={0}>
                    <Grid size={3}>
                        <Box>
                            <Typography fontSize={12} m={0}>{userInfo?.title}</Typography>
                            <Typography variant='h5' m={0}>{capitalizeFirstLetter(userInfo?.given_name)} {capitalizeFirstLetter(userInfo?.family_name)}</Typography>
                        </Box>
                    </Grid>
                    <Grid size={9}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {editing ? <ThemedButton
                                value={"Edit"}
                                type={"outlined"}
                                onClick={(event) => handleEditing(!editing)}
                                endIcon={<CreateIcon sx={{
                                    fontSize: 18
                                }} />}
                            />
                                : <></>}
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
            </Box>

            <Box>
                <Typography mb={2} variant="h6">About</Typography>
                <About
                    editing={editing}
                    summary={userInfo?.about_me}
                    links={userInfo?.social_link}
                    specializations={userInfo?.specializations}
                    languages={userInfo?.languages}
                    handleData={handleData}
                />
                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
            </Box>
            <Box>
                <Typography mb={2} variant="h6">Services</Typography>
                <Services
                    editing={editing}
                    needs_treated={userInfo?.needs_treated}
                    phone={userInfo?.phone}
                    office_address={userInfo?.office_address}
                    remote_visit={userInfo?.remote_visit}
                    first_visit_cost={userInfo?.first_visit_cost}
                    handleData={handleData}
                />
                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
            </Box>
            <Box>
                <Typography mb={2} variant="h6">Account</Typography>
                <Account
                    editing={editing}
                    piva={userInfo?.piva}
                    vat_address={userInfo?.vat_address}
                    email={userInfo?.email}
                    email_verified={userInfo && userInfo.email_verified ? userInfo.email_verified : false}
                    preferred_username={userInfo?.preferred_username}
                    given_name={userInfo?.given_name}
                    family_name={userInfo?.family_name}
                />
            </Box>
            {
                !editing ? <Box mt={3}>
                    <ThemedButton
                        value={"Annulla"}
                        type={"outlined"}
                        color={"secondary"}
                        style={{ marginRight: 15 }}
                        onClick={(event) => {
                            setEditing(!editing)
                            //setUndo(true)
                        }}
                        endIcon={<ReplayIcon sx={{
                            fontSize: 18
                        }} />}
                    />
                    <ThemedButton
                        value={"Save"}
                        type={"contained"}
                        color={"success"}
                        onClick={handleSave}
                        endIcon={<CreateIcon sx={{
                            fontSize: 18
                        }} />}
                    />
                </Box> : <></>
            }
        </Box>
    );
}

export default Profile