import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import ThemedListItemDrawer from '../../theme/ThemedListItemDrawer';
import UserIcon from './UserIcon';
import UserContent from './UserContent';
import { capitalizeFirstLetter } from '../../utils/dataUtils';

interface UserListProps {
    users?: UserItem[] | undefined
    messages?: string
    setContent: (content: React.ReactNode) => void;
}

export interface UserItem {
    email?: string
    firstName?: string
    lastName?: string
    id: string
    username?: string
}

const UserList: React.FC<UserListProps> = ({ users, setContent, messages }) => {

    const handleUserItems = () => {
        console.log("USERS ITEMS", users);
    }

    useEffect(() => { handleUserItems() }, [users])

    const minHeight = 50
    return (<>
        {!users || users.length == 0 ? messages :
            users.map(u => {
                return <Box>
                    <ThemedListItemDrawer
                        key={`${u.firstName} ${u.lastName}`}
                        text={`${capitalizeFirstLetter(u.firstName)} ${capitalizeFirstLetter(u.lastName)}`}
                        secondaryText={`${!u.email ? '' : u.email}`}
                        minHeight={minHeight}
                        icon={<UserIcon
                            firstName={u.firstName}
                            lastName={u.lastName}
                        />}
                        open={true}
                        setContent={setContent}
                        content={<UserContent user={u} />}
                    />
                </Box>
            })}
    </>)
}

export default UserList