import React, { useEffect, useState } from 'react';
import { UserItem } from "./UserList"
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { capitalizeFirstLetter } from '../../utils/dataUtils';
import Grid from '@mui/material/Grid2';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomDialog from '../../components/CustomDialog';
import { remove_attribute } from '../login/login_service';
import { getSavedAccessToken } from '../../utils/localStorageUtils';


interface UserContentProps {
    user: UserItem

}
const UserContent: React.FC<UserContentProps> = ({ user }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [dialogContent, setDialogContent] = useState<string>("Sei sicuro di voler dissociare questo utente? Se confermi non potrai più gestire il suo andamento")
    const [done, setDone] = useState<boolean>(false)

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        setOpenDialog(true)
    }

    const removeUser = async () => {
        const response = await remove_attribute("prof_id", getSavedAccessToken(), user.id)

        if (response.status == 200) {
            setDialogContent("Utente eliminato con successo")
        } else {
            setDialogContent("Errore inaspettato, contatta l'assistenza")
        }

        setDone(true)
    }

    return (<>
        <Grid container spacing={0} justifyContent="flex-end">
            <Grid size={10}>
                <Box color={'#757575'}>
                    <Typography fontSize={12} m={0}>Dashboard</Typography>
                    <Typography variant='h5' m={0}>{capitalizeFirstLetter(user.firstName)} {capitalizeFirstLetter(user.lastName)}</Typography>
                </Box>
            </Grid>
            <Grid>
                <IconButton
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <MenuItem>Ciao</MenuItem>
                    <Tooltip title={<Typography>Attenzione se disassoci un utente non potrai più tornare indietro</Typography>}>
                        <MenuItem onClick={handleRemove}>Disassocia Utenet
                            <ErrorOutlineIcon color='warning' style={{ fontSize: 20, marginLeft: 5 }} />
                        </MenuItem>
                    </Tooltip>
                </Menu>
            </Grid>
        </Grid>

        <CustomDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            dialogContent={dialogContent}
            actions={
                !done ?
                    <Button onClick={removeUser}>
                        Conferma
                    </Button>
                    :
                    <Button onClick={() => setOpenDialog(false)}>
                        Chiudi
                    </Button>
            }
        />
    </>)
}

export default UserContent