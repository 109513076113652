import { Box, Typography } from "@mui/material"
import ThemedTextField from "../../theme/ThemedTextField"
import { useState } from "react"

interface AccountProps {

    editing: boolean
    piva?: string
    vat_address?: string
    email?: string
    email_verified: boolean
    preferred_username?: string
    given_name?: string
    family_name?: string
}

const Account = (props: AccountProps) => {
    const [data, setData] = useState<Record<string, any | undefined>>()

    const { piva, vat_address, email, email_verified, preferred_username, given_name, family_name, editing } = props

    const [emailData, setEmailData] = useState<string>()
    const [nameData, setnameData] = useState<string>()
    const [lastnameData, setlastnameData] = useState<string>()
    const [pivaData, setpivaData] = useState<string>()    
    const [usernameData, setusernameData] = useState<string>()
    const [emailVerifiedData, setemailVerifiedData] = useState<string>()
    const [vatAddressData, setvatAddressData] = useState<string>()

    const handleEmail = (element: string) => {
        setEmailData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            email: element,
        }));
    }

    const handleName = (element: string) => {
        setnameData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            given_name: element,
        }));
    }

    const handleLastname = (element: string) => {
        setlastnameData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            family_name: element,
        }));
    }

    const handleVatAddress = (element: string) => {
        setvatAddressData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            vat_address: element,
        }));
    }

    const handlePiva = (element: string) => {
        setpivaData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            piva: element,
        }));
    }

    const handleVerifyMail = (element: string) => {
        console.log("email inviata con successo");
        
    }

    return (<>
        <Box>
            <ThemedTextField
                label="Username"
                disabled={true}
                required={true}
                defaultValue={preferred_username}
                type="text"
                fullWidth={true}
                focused={true}
                value={data?.preferred_username}
                style={{ marginBottom: 3 }}
            />
            <ThemedTextField
                label="E-mail"
                required={true}
                disabled={editing}
                defaultValue={email}
                type="text"
                fullWidth={true}
                focused={true}
                value={data?.email}
                onChange={(e) => handleEmail(e.target.value)}
                error={!email_verified}
                helperErrorText="Verifica il tuo indirizzo mail"
                style={{ marginBottom: 3 }}
            />
            <ThemedTextField
                label="Nome"
                required={true}
                disabled={editing}
                defaultValue={given_name}
                type="text"
                fullWidth={true}
                focused={true}
                value={data?.given_name}
                onChange={(e) => handleName(e.target.value)}
                style={{ marginBottom: 3 }}
            />
            <ThemedTextField
                label="Cognome"
                required={true}
                disabled={editing}
                defaultValue={family_name}
                type="text"
                fullWidth={true}
                focused={true}
                value={data?.family_name}
                onChange={(e) => handleLastname(e.target.value)}
                style={{ marginBottom: 3 }}
            />
            <ThemedTextField
                label="Indirizzo di fatturazione"
                required={true}
                disabled={editing}
                defaultValue={vat_address}
                type="text"
                fullWidth={true}
                focused={true}
                value={data?.vat_address}
                onChange={(e) => handleVatAddress(e.target.value)}
                style={{ marginBottom: 3 }}
            />
            <ThemedTextField
                label="P.Iva"
                required={true}
                disabled={editing}
                defaultValue={piva}
                type="text"
                fullWidth={true}
                focused={true}
                value={data?.piva}
                onChange={(e) => handlePiva(e.target.value)}
                style={{ marginBottom: 3 }}
            />
        </Box>
    </>)

}

export default Account